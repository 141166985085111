<template>
    <div style="height: 100%">
        <a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ height: '100%' }">
                    <a-col :span="18" style="height: 100%">
                        <div class="dialog_box">
                            <div class="list">
                                <div class="item" :id="'role_item_' + list.id" :class="{ active: list.id == roleActiveId }"
                                    v-for="list in newsData" :key="'role_' + list.id">
                                    <!-- @click="roleItem(list.id)" -->
                                    <router-link :to="{ name: 'newsArticles', query: { data: list.id } }" target="_blank">
                                        <div style="display: flex;">
                                            <div style="width: 160px;height: 120px;margin-right: 20px;margin-top: 3px;">
                                                <!-- <a-avatar shape="square" :size="120" :src="list.image" /> -->
                                                <img :src="list.image" width="160px" height="120px" alt="">
                                            </div>
                                            <div class="line1 ">
                                                <p style="font-weight: 700;">{{ list.title }}</p>
                                                <div class="line2" style="display: inline-block;margin-bottom: 10px;
                                                " v-for="(item, index) in list.tags.split(',')" :key="index">
                                                    <a-tag color="#108ee9">
                                                        {{ item }}
                                                    </a-tag>
                                                </div>
                                                <span class="line2">{{ (list.updatetime).substring(0, 10) }} </span>
                                                <div class="line2 c-line-2">{{ list.description }}</div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <a-pagination v-model="current" :total="total" show-less-items @change="changePagination" />
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="6" style="height: 100%">

                    </a-col>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
    props: {

    },
    data() {
        return {
            newsData: [],
            roleActiveId: 0,
            tagList: [],
            current: 1,
            total: null,
            data: {
                page: 1, //di几页
                pagesize: 10
            }
        }
    },
    computed: {
        ...mapGetters("user", ["token"]),
        ...mapGetters("app", ["config"])
    },
    created() {
        this.archives(this.data)
    },
    methods: {
        // 获取文章列表
        archives(data) {
            this.$http("drawing.archives", data).then(res => {
                if (res.code === 1) {
                    this.newsData = res.data.list
                    this.total = res.data.total
                }
            })
        },
        changePagination(page, pagesize) {
            let data = {
                page, pagesize
            }
            this.archives(data)
        },



        currentInfo(id) {
            this.$router.push({
                path: "/newsArticles",
                query: {
                    data: id
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
::v-deep .ant-collapse {
    border: none;
    background: transparent;

    .ant-collapse-item {
        border: none;

        .ant-collapse-header {
            padding: 12px 16px;

            .ant-collapse-arrow {
                left: 0;
            }
        }

        .ant-collapse-content {
            border: none;
            background: transparent;

            .ant-collapse-content-box {
                padding: 0;
            }
        }
    }
}

.dialog_box {
    height: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #f7f7f7;
    padding: 12px 0;

    .list {
        flex: 1;
        height: 100%;
        padding: 12px;
        overflow: hidden;
        padding-top: 0;

        &:hover {
            overflow-y: scroll;
            overflow-x: hidden;
            padding-right: 0;
        }

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 12px;
            border: 4px solid rgba(0, 0, 0, 0);
            box-shadow: 4px 0 0 #a5adb7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 4px 0 0 #4a4a4a inset;
        }

        // 滚动条两端按钮
        &::-webkit-scrollbar-button {
            height: 10px;
        }

        .item {
            padding: 12px;
            background: #fff;
            border-radius: 10px;
            margin-bottom: 12px;
            cursor: pointer;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                border: 1px solid #1890ff;
                box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
            }

            .line1 {
                color: #000;
                // font-weight: 700;
            }

            .line2 {
                margin-top: 4px;
                color: #999;
                // font-weight: 0;
            }
        }
    }
}

.content_box {
    height: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    // 滚动条整体
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    // 滚动条滑块
    ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: #a5adb7;
        border: 1px dashed rgba(0, 0, 0, 0);
        border-radius: 6px;
    }

    // 滚动条滑块 hover
    ::-webkit-scrollbar-thumb:hover {
        background: #4a4a4a;
    }

    // 滚动条轨道
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    // 滚动条两端按钮
    ::-webkit-scrollbar-button {
        height: 10px;
    }

    .output {
        flex: 1;
        border-radius: 12px;
        background: #f7f7f7;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        // margin-top: 10px;

        .list_box {
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            z-index: 1000;
            padding: 20px;
            margin: 0 6px;

            .list {
                margin-bottom: 20px;

                .item {
                    margin-bottom: 20px;
                    display: flex;
                    flex-wrap: nowrap;
                    $imgsize: 40px;

                    .avatar {
                        width: $imgsize;
                        height: $imgsize;
                        border-radius: 50%;
                        overflow: hidden;
                        border: 2px solid #fff;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .message {
                        max-width: calc(100% - #{$imgsize + 20px} * 2);
                        padding: 10px 12px;

                        ::v-deep .v-md-editor__right-area {
                            width: auto;
                            min-width: 0;
                        }

                        ::v-deep .vuepress-markdown-body {
                            padding: 0;
                        }
                    }

                    &.left {
                        justify-content: flex-start;

                        .avatar {
                            margin-right: 20px;
                        }

                        .message {
                            border-radius: 0 12px 12px 12px;
                            background: #fff;
                            color: #000;
                            position: relative;

                            &:hover {
                                .tools {
                                    display: flex;
                                }
                            }

                            .tools {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                transform: translateY(100%);
                                padding-top: 10px;
                                display: flex;
                                align-items: center;
                                display: none;

                                .copy,
                                .collect {
                                    padding: 4px 10px;
                                    border: 1px solid #eee;
                                    border-radius: 6px;
                                    cursor: pointer;

                                    &:hover {
                                        box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
                                        background: #fff;
                                    }

                                    span {
                                        margin-left: 4px;
                                    }
                                }

                                .collect {
                                    margin-left: 12px;
                                }
                            }
                        }
                    }

                    &.right {
                        justify-content: flex-end;

                        .avatar {
                            margin-left: 20px;
                        }

                        .message {
                            border-radius: 12px 0 12px 12px;
                            // background: $message_color;
                            background: #40A9FF;
                            color: #fff;

                            ::v-deep .vuepress-markdown-body {
                                // background: $message_color;
                                background: #40A9FF;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .input {
        margin-top: 20px;
        height: 120px;
        position: relative;
        background: #f7f7f7;
        border-radius: 12px;
        padding: 12px 84px 12px 12px;

        .textarea {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            resize: none;
            background: transparent;
            padding: 0;
            margin: 0;
        }

        .button {
            position: absolute;
            bottom: 12px;
            right: 12px;
        }
    }
}
</style>